export const QnFData = {
  BodyType: {
    Foreign: "BodyTypeForeign",
    SemiForeign: "BodyTypeSemiForeign",
    Intermediate: "BodyTypeIntermediate",
    SemiCobby: "BodyTypeSemiCobby",
    Cobby: "BodyTypeCobby",
    Small: "BodyTypeSmall",
    Large: "BodyTypeLarge",
    Lean: "BodyTypeLean",
    Dwarf: "BodyTypeDwarf",
    Oriental: "BodyTypeOriental",
  },
  Length: {
    Small: "LengthSmall",
    Medium: "LengthMedium",
    Large: "LengthLarge",
  },

  Origin: {
    Europe: {
      Greece: "OriginGreece",
      Cyprus: "OriginCyprus",
      UK: "OriginUK",
      France: "OriginFrance",
      Russia: "OriginRussia",
      Italy: "OriginItaly",
      Germany: "OriginGermany",
      Kuril: "OriginKuril",
      Norway: "OriginNorway",
    },

    Asia: {
      SEA: "OriginSEA",
      KSA: "OriginKSA",
      Myanmar: "OriginMyanmar",
      Thailand: "OriginThailand",
      China: "OriginChina",
      Japan: "OriginJapan",
      Iran: "OriginIran",
      Singapore: "OriginSingapore",
      Turkey: "OriginTurkey",
    },

    "South America": { Brazil: "OriginBrazil" },
    "North America": {
      USA: "OriginUSA",
      Canada: "OriginCanada",
    },
    Africa: {
      Egypt: "OriginEgypt",
      Kenya: "OriginKenya",
    },
    Australia: { Australia: "OriginAustralia" },
  },

  CoatLength: {
    All: "CoatLengthAll",
    Hairless: "CoatLengthHairless",
    Short: "CoatLengthShort",
    Medium: "CoatLengthMedium",
    Long: "CoatLengthLong",
    Rex: "CoatLengthRex",
  },
  CoatPattern: {
    Colorpoint: "CoatPatternColorpoint",
    All: "CoatPatternAll",
    Ticked: "CoatPatternTicked",
    Multicolor: "CoatPatternMulticolor",
    Solid: "CoatPatternSolid",
    Spotted: "CoatPatternSpotted",
    Tortoiseshell: "CoatPatternTortoiseshell",
    Tabby: "CoatPatternTabby",
    Brown: "CoatPatternBrown",
    White: "CoatPatternWhite",
    Blue: "CoatPatternBlue",
    Bicolor: "CoatPatternBicolor",
    Mackerel: "CoatPatternMackerel",
    Van: "CoatPatternVan",
    Gray: "CoatPatternGray",
  },
};
