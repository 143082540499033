import {
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useCallback, useMemo, useState } from "react";
import { StugnaES } from "stugna-es";
import "./App.css";
import btbg from "./btbg.jpg";
import CatDialog from "./CatDialog";
import catbg from "./catsbg2.png";
import { QnFData } from "./components/Questions/QnFData";
import { QuestionsGenerator } from "./components/Questions/QuestionsGenerator";
import { catRules } from "./components/Rules/catRules";

function App() {
  const generator = useMemo(() => new QuestionsGenerator(QnFData), []);
  const es = useMemo(() => new StugnaES({ toExplainMore: true }), []);
  const classes = useStyles();
  es.rulesImport(catRules);
  const [startState, setStartState] = useState(true);
  const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(false);

  const start = useCallback(async () => {
    await generator.questionGeneration();

    const q = await generator.getQuestion();
    setStartState(false);
    setQuestion(q);
  }, [generator]);

  const [cat, setCat] = useState(undefined);
  const [bodyTypeDialogOpen, setBodyTypeDialogOpen] = useState(false);
  const handleBodyTypeDialogClose = useCallback(() => {
    setBodyTypeDialogOpen(false);
  }, []);
  const handleBodyTypeDialogOpen = useCallback(() => {
    setBodyTypeDialogOpen(true);
  }, []);

  const answerCB = useCallback(
    (answer) => {
      setLoading(true);
      if (
        question?.category === "Origin" &&
        generator.getContinent() === null &&
        answer === 1
      ) {
        generator.setContinent();
      } else {
        if (answer === 1) {
          generator.setFilterCategory(question?.category);
        }
        es.factAdd({ name: question?.value, value: answer });
      }
      generator.deleteItem().then(() => {
        generator.getQuestion().then((res) => {
          if (es.factGet("cat")) {
            setCat(es.factGet("cat"));
            setQuestion(res);
            setLoading(false);

            return;
          } else {
            setQuestion(res);
            setLoading(false);

            return;
          }
        });
      });

      return;
    },
    [es, generator, question]
  );
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  let events = es.eventsAll();
  console.log(events);
  return (
    <div className={classes.container}>
      <Dialog
        open={bodyTypeDialogOpen}
        onClose={handleBodyTypeDialogClose}
        classes={{ paper: classes.btdialog }}
      >
        <div className={classes.bodyTypeImg}>
          <img src={btbg} alt="body type" width="100%" />
        </div>
        <a
          href="/btbg.jpg"
          rel="noopener noreferrer"
          target="_blank"
          className={classes.openInNew}
        >
          Open in a new tab
        </a>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <CatDialog onClose={handleClose} cat={cat?.value} />
      </Dialog>
      {startState ? (
        <motion.div
          key={question}
          variants={variants}
          animate={"show"}
          initial="hide"
          className={classes.text}
        >
          <Typography className={classes.welcome}>
            Welcome to Catinator
          </Typography>
          <Typography className={classes.desc}>
            {`A simple quiz to help you find the cat breed you're looking for.`}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={start}
              className={classes.button}
            >
              Start
            </Button>
          </div>
        </motion.div>
      ) : (
        <div style={{ userSelect: "none" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {!cat ? (
                <motion.div
                  key={question}
                  variants={variants}
                  animate={"show"}
                  initial="hide"
                >
                  {question?.text ? (
                    <div className={classes.text}>
                      {generator.category !== "BodyType" ? (
                        <Typography className={classes.question}>
                          {question?.text}
                        </Typography>
                      ) : (
                        <div>
                          <Typography className={classes.question}>
                            {question?.text}
                          </Typography>
                          <div className={classes.checkbt}>
                            <Typography className={classes.bodyTypeHelp}>
                              {`Need help with the body type? `}
                            </Typography>
                            <Typography
                              onClick={handleBodyTypeDialogOpen}
                              className={classes.link}
                            >
                              {" Check this out"}
                            </Typography>
                          </div>
                        </div>
                      )}
                      <div className={classes.buttonContainer}>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.button}
                          id="yes"
                          onClick={() => {
                            answerCB(1);
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.button}
                          onClick={() => {
                            answerCB(0);
                          }}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.text}>
                      <Typography className={classes.noCat}>
                        No cat found!
                      </Typography>
                      <Typography className={classes.tryAgain}>
                        Try again!
                      </Typography>
                      <div className={classes.buttonContainer}>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.button}
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          Restart
                        </Button>
                      </div>
                    </div>
                  )}
                </motion.div>
              ) : (
                <motion.div
                  key={question}
                  variants={variants}
                  animate={"show"}
                  initial="hide"
                  className={classes.text}
                >
                  <Typography className={classes.catTypeIs}>
                    The Cat Type Is:
                  </Typography>
                  <Typography className={classes.catType} onClick={handleOpen}>
                    {cfl(cat?.value)}!
                  </Typography>
                  <Typography className={classes.catHint}>
                    Hint: Click on the cat type for more info about the cat
                  </Typography>
                  <div className={classes.restartContainer}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Restart
                    </Button>
                  </div>
                </motion.div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(App);

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundImage: `url(${catbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  welcome: {
    fontSize: 20,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: 600,
  },
  text: {
    width: 500,
    background: theme.palette.background.default,
    boxShadow: theme.shadows[8],
    padding: 16,
    borderRadius: 8,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 36,
  },

  desc: {
    fontSize: 16,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: 400,
    marginTop: 8,
  },
  question: {
    fontSize: 20,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: 600,
  },
  bodyTypeHelp: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  checkbt: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 600,
    transition: "all 0.3s",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    marginLeft: 4,
  },
  noCat: {
    fontSize: 20,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: 600,
  },
  tryAgain: {
    fontSize: 16,
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: 8,
  },
  catType: {
    fontSize: 22,
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: 12,
    fontWeight: 600,
    transition: "all 0.2s ease-in-out",

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.dark,
    },
  },
  catTypeIs: {
    fontSize: 18,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  catHint: {
    fontSize: 14,
    color: theme.palette.text.hint,
    textAlign: "center",
  },
  restartContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 24,
  },

  dialog: {
    minWidth: 500,
    // background: theme.palette.background.default,
  },
  btdialog: {
    posotoin: "relative",
    minWidth: 900,
    background: "transparent",
  },
  bodyTypeImg: {
    position: "relative",
  },
  openInNew: {
    textDecoration: "none",
    background: "transparent",
    width: "fit-content",
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 600,
    transition: "all 0.3s",
    userSelect: "none",
    "&:hover": {
      cursor: "pointer",
      color: "#faebd7",
    },
  },
}));
export const variants = {
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
  hide: {
    opacity: 0,
  },
};
export function cfl(str) {
  let name = "";
  const arr = str.split(" ");
  arr.forEach((s, i) => {
    const upper = s.charAt(0).toUpperCase() + s.slice(1);
    name += upper + (arr.length - 1 === i ? "" : " ");
  });
  return name;
}
