import _ from "lodash";

export class QuestionsGenerator {
  constructor(questionsData) {
    this.continent = null;
    this.data = questionsData;
    this.category = null;
    this.filter = [];
    this.categories = [
      "Origin",
      "BodyType",
      "CoatLength",
      "CoatPattern",
      "Length",
    ];
    this.item = null;
    this.questions = {
      continents: [],
      BodyType: [],
      Length: [],
      CoatLength: [],
      CoatPattern: [],
      subContinents: {
        Asia: [],
        Europe: [],
        "South America": [],
        "North America": [],
        Africa: [],
        Australia: [],
      },
    };
  }
  setFilterCategory(category) {
    this.filter.push(category);
  }
  getContinent() {
    return this.continent;
  }
  async setContinent() {
    this.continent = this.questions.continents[this.item].key;
  }

  async setCategory(specificCategory) {
    let filteredCategories = this.categories.filter((category) => {
      return !this.filter.includes(category);
    });

    if (filteredCategories.length === 0) {
      filteredCategories = this.categories;
    }
    this.category =
      specificCategory ??
      filteredCategories[Math.floor(Math.random() * filteredCategories.length)];
    return;
  }

  async setItem() {
    if (this.category === "Origin") {
      if (this.continent) {
        this.item = Math.floor(
          Math.random() * this.questions.subContinents[this.continent].length
        );
        return;
      }
      this.item = Math.floor(
        Math.random() * Object.keys(this.questions.continents).length
      );
      return;
    }

    this.item = Math.floor(
      Math.random() * this.questions[this.category].length
    );
    return;
  }

  async deleteItem() {
    if (this.category === "Origin") {
      if (this.continent) {
        this.questions.subContinents[this.continent].splice(this.item, 1);
      } else {
        this.questions.continents.splice(this.item, 1);
      }
    } else {
      this.questions[this.category].splice(this.item, 1);
    }

    await this.checkEmptyAndDelete();
  }
  async checkEmptyAndDelete() {
    if (this.category === "Origin") {
      if (this.continent) {
        if (this.questions.subContinents[this.continent].length === 0) {
          delete this.questions.subContinents[this.continent];
          // eslint-disable-next-line array-callback-return
          this.questions.continents.findIndex((item, index) => {
            if (item.key === this.continent) {
              return this.questions.continents.splice(index, 1);
            }
          });

          this.continent = null;
        }
      }
    } else {
      if (this.questions[this.category].length === 0) {
        // eslint-disable-next-line array-callback-return
        this.categories.find((item, index) => {
          if (item === this.category) {
            this.categories.splice(index, 1);
          }
        });
        delete this.questions[this.category];
      }
    }
  }
  async setQuestion() {
    await this.setCategory();
    await this.setItem();
  }
  async getQuestion() {
    await this.setQuestion();
    if (this.category === "Origin") {
      if (this.continent) {
        return this.questions.subContinents[this.continent][this.item];
      }
      return this.questions.continents[this.item];
    }
    return this.questions[this.category][this.item];
  }

  async getFactValue() {
    if (this.category === "Origin") {
      if (!this.continent) {
        return undefined;
      }
      const value = this.questions[this.category][this.continent][this.item];
      // await this.deleteItem();
      // await this.checkEmptyAndDelete();

      return value;
    }
    const value = this.data[this.category][this.item];
    await this.deleteItem();
    await this.checkEmptyAndDelete();
    return value;
  }

  async questionGeneration() {
    _.map(this.data, (value, category) => {
      if (category === "Origin") {
        _.map(value, (value, key) => {
          const text = this.questionTextGenerator({ key, category });

          this.questions.continents.push({
            key,
            value: "continent",
            category,
            text,
          });
        });
        _.map(value, (val, continentName) => {
          _.map(val, (value, key) => {
            const text = this.questionTextGenerator({ key, category });

            this.questions.subContinents[`${continentName}`].push({
              key,
              value,
              category,
              text,
            });
          });
        });
      } else {
        _.map(value, (value, key) => {
          const text = this.questionTextGenerator({ key, category });

          this.questions[`${category}`].push({ key, value, category, text });
        });
      }
    });
  }

  questionTextGenerator(item) {
    if (item.category === "BodyType") {
      return `Does the cat have ${this.a_an(item.key[0])} ${
        item.key
      } body type?`;
    } else if (item.category === "Length") {
      return `Is the cat's body length ${item.key}?`;
    } else if (item.category === "Origin") {
      return `Is the cat from ${item.key}?`;
    } else if (item.category === "CoatLength") {
      if (item.key === "All") {
        return `Can the cat have all lengths of fur?`;
      } else if (item.key === "Hairless") {
        return `Is the cat hairless?`;
      } else {
        return `Is the cat's fur length ${item.key}?`;
      }
    } else if (item.category === "CoatPattern") {
      if (item.key === "All") {
        return `Can the cat have All coat patterns?`;
      } else {
        return `Does the cat have ${this.a_an(item.key[0])} ${
          item.key
        } fur pattern?`;
      }
    }
  }

  a_an(char) {
    if (/[aeiouAEIOU]/.test(char)) {
      return "an";
    }
    return "a";
  }
}
