export const catRules = [
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND OriginSEA = 1 AND CoatLengthShort = 1 AND (CoatPatternTicked = 1 OR CoatPatternTabby = 1)",
    factValue: "Abyssinian",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginGreece = 1 AND CoatLengthMedium = 1 AND CoatPatternMulticolor = 1",
    factValue: "Aegean",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthMedium = 1 AND CoatPatternAll = 1",
    factValue: "American bobtail",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthMedium = 1 AND CoatPatternAll = 1",
    factValue: "American curl",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "American shorthair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthRex = 1 AND CoatPatternAll = 1",
    factValue: "American wirehair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeLean = 1 AND LengthLarge = 1 AND OriginCyprus = 1 AND CoatLengthAll = 1 AND CoatPatternAll = 1",
    factValue: "Aphrodite giant",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginKSA = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "Arabian mau",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginUK = 1 AND CoatLengthShort = 1 AND CoatPatternSolid = 1",
    factValue: "Asian",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginAustralia = 1 AND CoatLengthShort = 1 AND CoatPatternSpotted = 1",
    factValue: "Australian mist",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND (OriginUSA = 1 OR OriginThailand =  1) AND CoatLengthLong = 1 AND CoatPatternColorpoint = 1",
    factValue: "Balinese",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeDwarf = 1 AND LengthSmall = 1 AND OriginUSA = 1 AND CoatLengthHairless = 1 AND CoatPatternAll = 1",
    factValue: "Bambino",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeLarge = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternSpotted = 1",
    factValue: "Bengal",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternBlack = 1)",
    factValue: "Bombay",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginBrazil = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "Brazilian shorthair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUK = 1 AND CoatLengthMedium = 1 AND CoatPatternAll = 1",
    factValue: "British longhair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthLarge = 1 AND OriginUK = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "British shorthair",
    factName: "cat",
  },
  {
    condition:
      "(BodyTypeSemiForeign = 1 OR BodyTypeSemiCobby = 1) AND LengthMedium = 1 AND (OriginMyanmar = 1 OR OriginThailand = 1) AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternTortoiseshell = 1)",
    factValue: "Burmese",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthShort = 1 AND (CoatPatternSpotted = 1 OR CoatPatternTabby = 1)",
    factValue: "California spangled",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternSolid = 1",
    factValue: "Chantilly tiffany",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginFrance = 1 AND CoatLengthShort = 1 AND CoatPatternBlue = 1",
    factValue: "Chartreux",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthLarge = 1 AND OriginEgypt = 1 AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternTabby = 1)",
    factValue: "Chausie",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeForeign = 1 AND (LengthSmall = 1 OR LengthMedium = 1) AND OriginUK = 1 AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Colorpoint shorthair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeForeign = 1 AND LengthSmall = 1 AND OriginUK = 1 AND CoatLengthRex = 1 AND CoatPatternAll = 1",
    factValue: "Cornish rex",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiCobby = 1 AND LengthMedium = 1 AND OriginUK = 1 AND CoatLengthLong = 1 AND CoatPatternAll = 1",
    factValue: "Cymric",
    factName: "cat",
  },
 
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND OriginUK = 1 AND CoatLengthRex = 1 AND CoatPatternAll = 1",
    factValue: "Devon rex",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND OriginRussia = 1 AND CoatLengthHairless = 1 AND CoatPatternSolid = 1",
    factValue: "Donskoy",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginChina = 1 AND CoatLengthShort = 1 AND (CoatPatternTicked = 1 OR CoatPatternTabby = 1)",
    factValue: "Dragon li",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginEgypt = 1 AND CoatLengthShort = 1 AND (CoatPatternSpotted = 1 OR CoatPatternTabby = 1)",
    factValue: "Egyptian mau",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginItaly = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "European shorthair",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "Exotic",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginCanada = 1 AND CoatLengthShort = 1 AND CoatPatternAll = 1",
    factValue: "Foldex",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiForeign = 1 AND (LengthSmall = 1 OR LengthMedium = 1) AND OriginGermany = 1 AND CoatLengthRex = 1 AND CoatPatternAll = 1",
    factValue: "German rex",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiForeign = 1 AND LengthMedium = 1 AND OriginUK = 1 AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternBrown = 1)",
    factValue: "Havana brown",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Highlander",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternColorpoint = 1",
    factValue: "Himalayan",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND (OriginChina = 1 OR OriginJapan = 1) AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Japanese bobtail",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND (LengthSmall = 1 OR LengthMedium = 1) AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternColorpoint = 1",
    factValue: "Javanese",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthSmall = 1 OR LengthMedium = 1) AND OriginThailand = 1 AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternWhite = 1)",
    factValue: "Khao manee",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiCobby = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginKuril = 1 AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Kurilian bobtail",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthLarge = 1 AND OriginUSA = 1 AND CoatLengthRex = 1 AND CoatPatternAll = 1",
    factValue: "Laperm",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeLarge = 1 AND LengthLarge = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternAll = 1",
    factValue: "Maine coon",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginUK = 1 AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Manx",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginSEA = 1 AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Mekong bobtail",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeForeign = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthMedium = 1 AND (CoatPatternSolid = 1 OR CoatPatternBlue = 1)",
    factValue: "Nebelung",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthLarge = 1 AND OriginNorway = 1 AND CoatLengthLong = 1 AND (CoatPatternAll = 1 AND CoatPointColorpoint = 0)",
    factValue: "Norwegian forest",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeLarge = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthShort = 1 AND (CoatPatternSpotted = 1 OR CoatPatternTabby = 1)",
    factValue: "Ocicat",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND (OriginUSA = 1 OR OriginThailand = 1) AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Oriental",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternBicolor = 1",
    factValue: "Oriental bicolor",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginIran = 1 AND CoatLengthLong = 1 AND (CoatPatternAll = 1 AND CoatPointColorpoint = 0)",
    factValue: "Persian",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND OriginRussia = 1 AND CoatLengthHairless = 1 AND CoatPatternAll = 1",
    factValue: "Peterbald",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthLarge = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND (CoatPatternSpotted = 1 OR CoatPatternTabby = 1)",
    factValue: "Pixie bob",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthLarge = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternAll = 1",
    factValue: "Ragamuffin",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthLarge = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND CoatPatternColorpoint = 1",
    factValue: "Ragdoll",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginRussia = 1 AND CoatLengthShort = 1 AND (CoatPatternSolid = 1 OR CoatPatternBlue = 1)",
    factValue: "Russian blue",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeLarge = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternSpotted = 1",
    factValue: "Savannah",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUK = 1 AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Scottish fold",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND (CoatLengthShort = 1 OR CoatLengthLong = 1) AND CoatPatternAll = 1",
    factValue: "Selkirk rex",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternSpotted = 1",
    factValue: "Serengeti",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND OriginThailand = 1 AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Siamese",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiCobby = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginRussia = 1 AND CoatLengthMedium = 1 AND CoatPatternAll = 1",
    factValue: "Siberian",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSmall = 1 AND LengthSmall = 1 AND OriginSingapore = 1 AND CoatLengthShort = 1 AND (CoatPatternTicked = 1 OR CoatPatternTabby = 1)",
    factValue: "Singapura",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND (LengthMedium = 1 OR LengthLarge = 1) AND OriginUSA = 1 AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Snowshoe",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginKenya = 1 AND CoatLengthShort = 1 AND (CoatPatternTicked = 1 OR CoatPatternTabby = 1)",
    factValue: "Sokoke",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeCobby = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthLong = 1 AND (CoatPatternTicked = 1 OR CoatPatternTabby = 1)",
    factValue: "Somali",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND OriginCanada = 1 AND CoatLengthHairless = 1 AND CoatPatternAll = 1",
    factValue: "Sphynx",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginThailand = 1 AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Thai",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeOriental = 1 AND LengthMedium = 1 AND (OriginUSA = 1 OR OriginThailand = 1) AND CoatLengthShort = 1 AND CoatPatternColorpoint = 1",
    factValue: "Tonkinese",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginUSA = 1 AND CoatLengthShort = 1 AND (CoatPatternMackerel = 1 OR CoatPatternTabby = 1)",
    factValue: "Toyger",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiCobby = 1 AND LengthMedium = 1 AND OriginTurkey = 1 AND CoatLengthMedium = 1 AND CoatPatternAll = 1",
    factValue: "Turkish angora",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeSemiCobby = 1 AND LengthLarge = 1 AND OriginTurkey = 1 AND CoatLengthMedium = 1 AND CoatPatternVan = 1",
    factValue: "Turkish van",
    factName: "cat",
  },
  {
    condition:
      "BodyTypeIntermediate = 1 AND LengthMedium = 1 AND OriginRussia = 1 AND CoatLengthHairless = 1 AND (CoatPatternSolid = 1 OR CoatPatternGray = 1)",
    factValue: "Ukrainian levkoy",
    factName: "cat",
  },
];
