import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { catsDescs } from "./components/Rules/catsDesc";
import { storage } from "./firebase";

function CatDialog(props) {
  const classes = useStyles();
  const { cat, onClose } = props;
  const [imagesList, setimagesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    storage
      .ref(cat)
      .listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.getDownloadURL().then((url) => {
            setimagesList((imagesList) => [...imagesList, url]);
          });
        });
      })
      .then(() => setLoading(false));
  }, [cat]);

  return (
    <div className={classes.container}>
      {loading ? (
        <div
          style={{
            width: "100$",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={60} />
        </div>
      ) : (
        <Carousel
          {...set}
          activeIndicatorIconButtonProps={{
            className: classes.activeIndicator,
          }}
          indicatorIconButtonProps={{ className: classes.indicator }}
          indicatorContainerProps={{
            style: {
              position: "absolute",
              bottom: 24,
              zIndex: 9999,
            },
          }}
        >
          {imagesList.map((x, idx) => {
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img width={600} src={x} alt="" />
              </div>
            );
          })}
        </Carousel>
      )}

      <motion.div
        key={loading}
        variants={variants}
        animate={"show"}
        initial="hide"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          padding: 16,
          boxSizing: "border-box",
          "&::-webkit-scrollbar": {
            width: "5px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "rgba(55, 65, 81, 0.48)",
            borderRadius: 8,
          },
        }}
      >
        <div className={classes.textContainer}>
          <Typography className={classes.catTitle}>{cfl(cat)}</Typography>
          <Typography className={classes.catDescription}>
            {catsDescs[cat]}
          </Typography>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            onClick={onClose}
            className={classes.discardButton}
            variant="contained"
            color="primary"
          >
            Done
          </Button>
        </div>
      </motion.div>
    </div>
  );
}

export default CatDialog;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    overflow: "auto",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(55, 65, 81, 0.48)",
      borderRadius: 8,
    },
    boxSizing: "border-box",
  },
  activeIndicator: {
    color: `${theme.palette.primary.main} !important`,
    transition: "all 0.3s ease !important",

    "&:hover": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },
  indicator: {
    transition: "all 0.3s ease !important",
    "&:hover": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },

  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    justifyContent: "space-between",
    height: 280,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 24,
  },

  discardButton: {
    alignSelf: "flex-end",
    marginInlineEnd: 16,
    width: 100,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    marginTop: 16,
  },
  catTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  catDescription: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 400,
    paddingInline: 16,
    color: theme.palette.text.primary,
  },
}));
const set = {
  autoPlay: true,
  animation: "fade",
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: false,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
};
export const variants = {
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeOut",
      duration: 1,
    },
  },
  hide: {
    opacity: 0,
  },
};
export function cfl(str) {
  let name = "";
  const arr = str.split(" ");
  arr.forEach((s, i) => {
    const upper = s.charAt(0).toUpperCase() + s.slice(1);
    name += upper + (arr.length - 1 === i ? "" : " ");
  });
  return name;
}
