export const catsDescs = {
  Abyssinian:
    "The Abyssinian is a breed of domestic short-haired cat with a distinctive ticked tabby coat, in which individual hairs are banded with different colors. They are also known simply as Abys. The source of the name is not because Ethiopia, formerly Abyssinia, is thought to be the original home of these cats, but because the first Abyssinians exhibited in shows in England were reported to have been imported to England from there. Its true origin according to genetic studies is likely to have been the coast of the Indian Ocean in parts of Southeast Asia, and its introduction to Abyssinia (and others) was through colonialists and merchants stopping through Calcutta.",

  Aegean:
    "Aegean cats are a naturally occurring landrace of domestic cat originating from the Cycladic Islands of Greece and western Turkey. It is considered a natural cat, developing without human interference. Development of the Aegean cat as a formal breed began in the early 1990s by breeders in the fledgling Greek cat fancy. It is considered to be the only native Greek variety of cat.",

  "American bobtail":
    "The American Bobtail is an uncommon breed of domestic cat which was developed in the late 1960s. It is most notable for its stubby bobbed tail about one-third to one-half the length of a normal cat's tail. This is the result of a cat body type genetic mutation affecting the tail development.",

  "American curl":
    "The distinctive feature of the American Curl is their attractive, uniquely curled-back ears. The breed originated in Lakewood, California, as the result of a spontaneous mutation.",

  "American shorthair":
    "The American Shorthair is a breed of domestic cat believed to be descended from European cats brought to North America by early settlers to protect valuable cargo from mice and rats.",

  "American wirehair":
    "The American Wirehair cat resulted as a spontaneous mutation. Their coat, which is not only springy, dense, and resilient, but also coarse and hard to the touch, distinguishes the American Wirehair from all other breeds.",

  "Aphrodite giant":
    "The Aphrodite Giant cat breed is one of two naturally occurring breeds from Cyprus. Experts believe that these particular felines developed throughout the mountainous regions in Cyprus and, thanks to their large size, these cats easily hunted large prey animals.",

  "Arabian mau":
    "The Arabian Mau is a formal breed of domestic cat, originated from the early African wildcat, a short-haired landrace native to the Arabian Peninsula. It lives in the streets of the Arabian Peninsula and has adapted very well to its extreme climate. Based on one landrace, the Arabian Mau is a natural breed.",

  Asian:
    "The Asian, also known as the Malayan, is a cat breed similar to the Burmese but in a range of different coat colours and patterns. Long-haired Asians of all varieties are called Tiffanies. Asians are grouped in the foreign section at cat shows.",

  "Australian mist":
    "The Australian Mist is a breed of cat developed in Australia. It is a cross between the Abyssinian cat, the Burmese cat, and the Australian Tabby cat.",

  Balinese:
    "The Balinese is a long-haired breed of domestic cat with Siamese-style point coloration and sapphire-blue eyes. The Balinese is also known as the purebred long-haired Siamese, since it originated as a natural mutation of that breed and hence is essentially the same cat but with a medium-length silky coat and a distinctively plumed tail.",

  Bambino:
    "The Bambino is a breed of cat that was created as a cross between the Sphynx and the Munchkin breeds. The Bambino cat has short legs, large upright ears, and is usually hairless.",

  Bengal:
    "The Bengal cat is a domesticated cat breed created from a hybrid of the Asian leopard cat, with domestic cats, especially the spotted Egyptian Mau. The breed name comes from the leopard cat's taxonomic name.",

  Bombay:
    "The Bombay cat is a type of short-haired cat developed by breeding sable Burmese and black American Shorthair cats, to produce a cat of mostly Burmese type, but with a sleek, panther-like black coat. Bombay is the name given to black cats of the Asian group.",

  "Brazilian shorthair":
    "The Brazilian Shorthair, which is also known as “Pelo Curto Brasileiro,” is the first feline breed to come from Brazil and receive international recognition. This is actually the common street cat of Brazil, much like the American Shorthairs and the British Shorthairs of the US and UK, respectively.",

  "British longhair":
    "The British Longhair is a medium-sized, semi-long-haired breed of domestic cat, originating in Great Britain.",

  "British shorthair":
    "The British Shorthair is the pedigreed version of the traditional British domestic cat, with a distinctively stocky body, dense coat, and broad face. The most familiar colour variant is the British Blue, with a solid grey-blue coat, orange eyes, and a medium-sized tail. The breed has also been developed in a wide range of other colours and patterns, including tabby and colourpoint.",

  Burmese:
    "The Burmese cat is a breed of domestic cat, originating in Burma, believed to have its roots near the Thai-Burma border and developed in the United States and Britain.",

  "California spangled":
    "The California Spangled is a breed of domestic cat that was bred to resemble spotted wild cats, like the ocelot and leopard. California Spangled cats are a crossbreed of many strains, including the Abyssinian, American Shorthair and British Shorthair. Despite their wild appearance, they are completely domestic.",

  "Chantilly tiffany":
    "The Chantilly-Tiffany or Chantilly/Tiffany, also known as the Chantilly or the Foreign Longhair, is a breed of domestic cat which originated in North America. The Chantilly was thought to be extinct but later rediscovered in 1960.",

  Chartreux:
    "The Chartreux is a rare breed of cat from France, it is large and muscular with relatively short, fine-boned limbs, and very fast reflexes. They are known for their blue (silver-grey) water-resistant short hair double coats which are often slightly thick in texture and orange or copper-coloured eyes.",

  Chausie:
    "The Chausie is a domestic breed of cat that was developed by breeding a few individuals from the non-domestic species jungle cat to a far greater number of domestic cats.",

  "Colorpoint shorthair":
    "Colorpoint Shorthairs are a variety of domestic cats. The variety was initially created by crossbreeding Siamese with the American Shorthair – the same mixture that created the Oriental Shorthair.",

  "Cornish rex":
    "The Cornish Rex is a domestic cat breed. The coat of a Cornish Rex is extremely fine and sometimes curly. The curl in their fur is caused by a different mutation and gene than that of the Devon Rex. The breed originated in Cornwall, Great Britain.",

  Cymric:
    "The Cymric is a domestic cat breed. According to Isle of Man records, the taillessness trait of the Cymric began as a mutation among the island's domestic cat population. Given the island's closed environment and small gene pool, the dominant gene that decided the cats' taillessness was easily passed from one generation to the next, along with the gene for long hair.",

  "Devon rex":
    "The Devon Rex is a tall-eared, short-haired breed of cat that emerged in England during the late 1950s. They are known for their slender bodies, wavy coat, and large ears. This breed of cat is capable of learning difficult tricks but can be hard to motivate.",

  Donskoy:
    "The Donskoy cat, also known as Don Sphynx or Russian Hairless, is a hairless cat breed of Russian origin. It is not related to the better-known Sphynx cat, whose characteristic hairlessness is caused by a recessive mutation in the keratin 71 gene. The Donskoy's hairlessness, on the other hand, is caused by a dominant mutation.",

  "Dragon li":
    "The Dragon li is a rare cat that’s also quite new because it has only recently been developed as an official feline breed. The first showing of this breed in Beijing was in 2003. However, it does have an ancient history, dating back to 1300 BC. As a result, it’s one of the oldest feline breeds that are native to China.",

  "Egyptian mau":
    "Egyptian Maus are a small to medium-sized short-haired cat breed. They are one of the few naturally spotted breeds of domesticated cat. The spots of the Mau occur on only the tips of the hairs of its coat. It is considered a rare breed.",

  "European shorthair":
    "The European Shorthair may be the most common feline in Europe, and it may also be the oldest feline breed in continental Europe. In fact, the breed dates back to the time of the emperors in Rome roughly 2,000 years ago. And, thanks to the Romans, these cats spread throughout the European continent, where they were appreciated on farms because they had great hunting abilities and could help control rodent populations.",

  Exotic:
    "The Exotic cat was created in the 1950s with the express goal of developing a cat that looked like a Persian, but without the long, high-maintenance coat. To achieve the desired appearance, crossbreeding included the American Shorthair, Burmese, and British Shorthair.",

  Foldex:
    "The Foldex cat, also known as the Exotic Fold, is a breed of cat developed in the Canadian province of Quebec. Foldexes are medium-sized cats with a rounded face, short legs, and folded ears. The latter are the defining feature of the breed. Their eyes are well-rounded and wide open, with pair of ears with small and smooth-edged tips.",

  "German rex":
    "The German Rex is a medium-sized breed with slender legs of a medium length. The head is round with well-developed cheeks and large, open ears. The eyes are of medium size in colours related to the coat colour. The coat is silky and short with a tendency to curl. The whiskers also curl, though less strongly than in the Cornish Rex. They may be nearly straight. All colours of coat, including white, are allowed. The body development is heavier than in the Cornish Rex - more like the European Shorthairs.",

  "Havana brown":
    "The Havana Brown breed was created by a group of British enthusiasts in the 1950s when domestic black cats were crossed with Siamese in a planned breeding program. Early on there were some Russian Blue genes in the pool, but they are no longer present. The breeding pool in both the U.S. and Britain is extremely small, making the breed endangered. They are bred at only a dozen or so catteries.",

  Highlander:
    "The Highlander has physical traits that resemble that of a wildcat, making it a truly unique looking breed. It was created in 2004 when two hybrid breeds, the Desert Lynx and the Jungle Curl, were crossed. Because it was produced from two hybrids, though, this breed does not actually have any wildcat genes, so it is a docile and playful companion. Originally named the Highland Lynx, the name was changed to Highlander in 2005.",

  Himalayan:
    "The Himalayan breed is derived from the Persian, and is the result of efforts to create a colorpoint version of that breed. The selective cross breeding of Persians and Siamese was attempted in the U.S. and Great Britain as early as the 1920s, but was undertaken in earnest in the U.S. in the early 1950s. The offspring of these early pairings were then bred back to Persian cats to preserve the body conformation and long hair of that breed while keeping the color points of the Siamese. The name “Himalayan” was taken from the color patterns found on goats and rabbits that are common to the Himalayan Mountains and are similar to those seen with these cats.",

  "Japanese bobtail":
    "One of the oldest of all the naturally occurring breeds of cat, the Japanese Bobtail is a native of Japan and first appears in written records more than a thousand years ago. It is generally accepted that the first cats of this breed helped Buddhist monks protect their rice paper scrolls from rats. In Japan, the Bobtail is a street cat and remains a premier mouser.",

  Javanese:
    "The Javanese is a variety of purebred domestic cats. The variety was developed in North America; its name is derived from the tradition of naming Oriental-type cats after Southeast Asian places. It is an offshoot of the Balinese breed, out-crossed to Siamese and Colorpoint Shorthair, and often having coat patterns not acceptable in those breeds. Individuals may or may not be have point coloration.",

  "Khao manee":
    "The Khao Manee cat is a rare breed of cat originating in Thailand, which has an ancient ancestry tracing back hundreds of years. Khao Manee cats are pure white with a short, smooth, close-lying coat. They can have blue eyes, gold eyes or odd-eyes with one of each colour. The odd-eyed Khao Manee is the rarest variety. They are muscular, athletic cats of moderate foreign type and are reputed to be active, communicative and intelligent.",

  "Kurilian bobtail":
    "The Kurilian Bobtail is a feline breed that naturally evolved on the Kuril Islands between Japan and Siberia. The people of the Kuril Islands have valued these cats for more than 200 years because they are incredible hunters who can manage rodent populations with ease. In fact, they are known for hunting in packs, making them especially formidable to prey, though they can certainly hunt alone as well, even fishing salmon out of the water.",

  Laperm:
    "The LaPerm is a rex breed which originated in the United States and is now present in many other countries worldwide. The breed is genetically unique and not related to any other rex cat varieties, having a dominant gene causing their curly coats. They have an elegant and athletic build and are affectionate, active, and outgoing in character. They are reputed to be hypoallergenic cats, provoking a significantly lower level of an allergic response in humans than normal cats. Their most significant feature is their coat, which is made up of soft waves, curls, and ringlets, resembling a shaggy perm.",

  "Maine coon":
    "The Maine Coon is a large domesticated cat breed. It is one of the oldest natural breeds in North America. The breed originated in the U.S. state of Maine, where it is the official state cat. The breed was popular in cat shows in the late 19th century, but its existence became threatened when long-haired breeds from overseas were introduced in the early 20th century. The Maine Coon has since made a comeback and is now the third most popular pedigreed cat breed in the world.",

  Manx: "The Manx cat is a breed of domestic cat originating on the Isle of Man, with a naturally occurring mutation that shortens the tail. Many Manx have a small stub of a tail, but Manx cats are best known as being entirely tailless; this is the most distinguishing characteristic of the breed, along with elongated hind legs and a rounded head.",

  "Mekong bobtail":
    "The Mekong Bobtail (formerly known as the Thai Bobtail) is a breed of cat. Originally named for Thailand, the country of origin of its progenitors, the breed is now named after the Mekong River. It is naturally distributed throughout parts of Asia and was developed as a breed in Russia.",

  Nebelung:
    "The Nebelung is a rare pedigree breed of domestic cats which was developed in the 1980s. Nebelungs have long bodies, wide-set green eyes, long and dense fur, and mild dispositions. The cat is related to the Russian Blue, but with longer, silkier hair, and is in fact sometimes called the Long-haired Russian Blue. ",

  "Norwegian forest":
    "The Norwegian Forest cat is a breed of domestic cat originating in Northern Europe. This natural breed is adapted to a very cold climate, with a top coat of long hair and a woolly undercoat for insulation. The breed's ancestors may have been a landrace of short-haired cats brought to Norway about A.D. 1000 by the Vikings, who may also have brought with them long-haired cats, like those ancestral to the modern Siberian and Turkish Angora. During World War II, the Norwegian Forest cat was nearly extinct; then the Norwegian Forest Cat Club's breeding program increased the cat's number.",

  Ocicat:
    "The spotted Ocicat is the result of interbreeding the Abyssinian and Siamese. It is a completely domestic cat, with no wild blood in its heritage. The breed is unusual in that it is spotted like a wild cat but has the temperament of a domestic animal. It is named for its resemblance to the ocelot.",

  Oriental:
    "The Oriental breed was developed in England in the 1950s. They were part of a post-World War II effort to explore all the possible color and coat permutations of the highly popular and intelligent Siamese. After many misadventures in trying to name each of these cats, they designation “Oriental” was accepted to encompass a breed so large that some 600 color, pattern, and coat length combinations are considered acceptable.",

  "Oriental bicolor":
    "The Oriental Bicolor originated in the United States in 1979, when Lindajean Grillo began crossing bicolor American Shorthair cats with Siamese cats. Out of all of the offspring, she picked the best bicolor cats to then mate back to Orientals or Siamese.",

  Persian:
    "It is generally accepted that the Persian cat originated in Persia (now Iran) and parts of Turkey. The first Persians were brought to Europe by returning Crusaders, and may have been crossbred with Angora cats in Italy by the 16th century. These cats were highly valued by European royalty, and did not make their way to the United States until late in the 19th century where their popularity spread rapidly.",

  Peterbald:
    "The Peterbald is a new hairless cat breed that originated in Russia in the early part of the 1990s. In 1994, a hairless breed known as the Donskoy was bred with the Oriental Shorthair. Because it became really popular in Saint Petersburg, Russia, the name Peterbald was given to this new and unique cat breed.",
  "Pixie bob":
    "The Pixie-bob is a breed of domestic cat claimed to be the progeny of naturally occurring bobcat hybrids. However, DNA testing has failed to detect bobcat marker genes, and Pixie-bobs are considered wholly domestic for the purposes of ownership, cat fancy registration, and import and export. They were, however, selected and bred to look like American bobcats.",
  Ragamuffin: `The Ragamuffin is a breed of domestic cat. It was once considered to be a variant of the Ragdoll cat but was established as a separate breed in 1994. Ragamuffins are notable for their friendly personalities and thick fur.`,
  Ragdoll:
    "The Ragdoll is a breed of cat with a distinct colorpoint coat and blue eyes. Its morphology is large and weighty, and it has a semi-long and silky soft coat. American breeder Ann Baker developed Ragdolls in the 1960s. They are best known for their docile, placid temperament and affectionate nature. The name 'Ragdoll' is derived from the tendency of individuals from the original breeding stock to go limp and relaxed when picked up.",
  "Russian blue": `The Russian Blue is a cat breed with colors that vary from a light shimmering silver to a darker, slate grey. The short, dense coat, which stands out from the body, has been the breed's hallmark for more than a century.`,
  Savannah: `The Savannah is a hybrid cat breed. It results from a cross between a serval and a domestic cat. This hybridization typically produces large and lanky offspring with vibrant spotted coats.`,
  "Scottish fold": `The Scottish Fold is a breed of domestic cat with a natural dominant gene mutation that affects cartilage throughout the body, causing the ears to "fold", bending forward and down towards the front of the head, which gives the cat what is often described as an "owl-like" appearance.`,
  "Selkirk rex":
    "The Selkirk Rex is a new breed that developed naturally from a curly coated tortie and white housecat found in a shelter in Montana in 1987. The Selkirk Rex has a patient, loving, and tolerant personality.",
  Serengeti:
    "The first Serengeti cat was bred by Karen Sausman, who owns the Kingsmark Cattery in California. This conservation biologist wanted to create a new domestic cat breed that would appear much like the gorgeous African Serval, which is a wild cat. Even though this breed is similar to the Savannah breed, it has no wild Serval blood in its lineage. Instead, these cats were developed by crossing Oriental Shorthairs with Bengals, giving them a wild appearance without the wild tendencies.",
  Siamese:
    "One of the first cat breeds native to Asia to be recognized officially, Siamese cats are believed to be in existence since ancient times. Descriptions and illustrations that fit the distinct looks of what we today know as Siamese cats have been found in ancient manuscripts dated 14 to 18 century Thailand. The exotic beauty and unique character of the Siamese cats made sure, however, that the breed found its way to other parts of the world. In the late 1800s, the first Siamese cats arrived in the West, first in the United States of America where a Siamese was a gift for the president of the country, and somewhat later to the United Kingdom and beyond.",
  Siberian:
    "The Siberian is a centuries-old landrace (natural variety) of domestic cat in Russia and recently developed as a formal breed with standards promulgated the world over since the late 1980s",
  Singapura: `The Singapura is the smallest breed of cat, noted for its large eyes and ears, ticked coat, and blunt tail. Reportedly established from three "drain cats" imported from Singapore in the 1970s, it was later revealed that the cats were originally sent to Singapore from the US before they were exported back to the US. Investigations by the Cat Fanciers' Association (CFA) concluded no wrongdoing and the Singapura kept its status as a natural breed.`,
  Snowshoe: `The Snowshoe is a breed of cat originating in the United States of America in the 1960s. Snowshoes were first produced in Philadelphia when a Siamese breeder's cat gave birth to three kittens with white feet. The breeder, Dorothy Hinds-Daugherty, then began a breeding program to produce what were originally called "Silver Laces", crossing the strangely marked Siamese cats with bi-color American Shorthair cats and other breeds.`,
  Sokoke:
    "The Sokoke is an old, but rare breed of domestic cat from the Arabuko Sokoke Forest in Kenya. DNA testing has proven the cats are not a hybrid cross with wild cats as once believed, but are descended from Asian cats that are themselves the descendants of wild cats from Arabia.",
  Somali:
    "The Somali cat is genetically similar to the Abyssinian. Due to inheriting 2 copies of the recessive gene for long hair, they have a characteristic luscious coat, unlike their cousin, the Abyssinian cat.",
  Sphynx:
    "The Sphynx cat (pronounced SFINGKS) also known as the Canadian Sphynx, is a breed of cat known for its lack of fur. Hairlessness in cats is a naturally occurring genetic mutation, and the Sphynx was developed through selective breeding of these animals, starting in the 1960s",
  Thai: "The Thai cat breed originated in Thailand, where it is actually referred to as the Wichien-Maat, which translates to “moon diamond.” This breed is also commonly referred to as the Traditional, or Old-Style, Siamese.",
  Tonkinese: `Tonkinese is a domestic cat breed produced by crossbreeding between the Siamese and Burmese. Members of the breed share many of their parents' distinctively lively, playful personality traits and are similarly distinguished by a pointed coat pattern in a variety of colors. In addition to the modified coat colors of the "mink" pattern, which is a dilution of the point color (as in watercolors), the breed is now being shown in the foundation-like Siamese and Burmese colors: pointed with white and solid overall (sepia).`,
  Toyger:
    "The name “Toyger” is a combination of the words “toy” and “tiger,” and when you see a Toyger, you will immediately know why it has been given this name: it looks like a miniature tiger.",
  "Turkish angora":
    "The Turkish Angora likely originated in the mountains of Turkey, where its medium-length coat protected it from harsh winter conditions. There may be a genetic link to the Manul cat, a wild feline domesticated by the Tartars. The Turkish Angora is a pure and natural breed, which has a written history dating back to France in the 16th century. In the early 1900s, the Turkish Angora was used heavily in Persian breeding programs, and was in danger of disappearing as a distinct breed until rescued by enthusiasts in carefully controlled breeding programs in Turkey. The cat was introduced in America in the 1950s, but all Turkish Angoras must be able to trace their ancestry back to Turkey to qualify for registration.",
  "Turkish van":
    "The Turkish Van originated in central and southwest Asia and likely came to Europe with returning Crusaders. The term “Van” is common to the region encompassing Iran, Iraq, eastern Turkey, and the southwest Soviet Union. The cats appeared in England in 1955 simply as “Turkish” cats, and the “Van” distinction was added to prevent confusion with the Turkish Angora, which is a completely separate breed. The Turkish Van only arrived in the U.S. in 1982, and they are still relatively rare. They are a natural breed and are regarded in their homeland as a treasure.",
  "Ukrainian levkoy":
    "The Ukrainian Levkoy is a cat breed of distinct appearance, having inward-folding ears and little to no hair. These cats are of medium size with a longish body, appearing both muscular and slender. They have soft, elastic skin; an excess of which leads to a wrinkled appearance. The breed is not recognized by any major, international cat fancier and breeder organizations, only Ukrainian and Russian clubs.",
};
